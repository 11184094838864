import React, { useContext, useEffect, useMemo } from "react";
import { Container } from "react-bootstrap";
import MainHeader from "../Layout/Header";
// import DisconnectedWallet from '../../components/DisconnectedWallet';
// import Footer from "../../components/CustomFooter";
import { AppContext } from "../../scripts/context";
import BalanceDetails from "./BalanceDetails/components";
import Footer from "../Layout/Footer";

function Index() {
  const { state: { user } = {} } = useContext(AppContext) || {};

  const ComponentToRender = useMemo(
    () => user?.address && <BalanceDetails />,
    [user?.address]
  );

  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add("Visting");

    // Remove class from body when component unmounts
    return () => {
      document.body.classList.remove("Visting");
    };
  }, []); // Empty dependency array ensures this effect runs only once (on mount)

  return (
    <Container fluid>
      <MainHeader />
      {ComponentToRender}
      <Footer />
    </Container>
  );
}

export default Index;
