import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/CustomTable";
import Pagination from "./Pagenation";
import "./style.css";
import { getUserPurchasedTransactionsList } from "../../presaleContractFunctions";
import { useEthersSigner } from "../../../../hooks/useEthersSigner";
import { ethers } from "ethers";

function PurchasedPartner({ partnerData, purchasePrice }) {
  const signer = useEthersSigner();
  // console.log("table", partnerData?.transactions);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [tableTotalList, setTableTotalList] = useState(0);
  const [pages, setPages] = useState(0);
  useEffect(() => {
    if (partnerData) {
      console.log("partnerDatazzzzz", partnerData);

      setTableTotalList(partnerData.length);
      setPages(Math.ceil(partnerData.length / limit) || 1);
    }
  }, [partnerData, limit]);

  const transactionList = async (limit) => {
    const start = (currentPage - 1) * limit;
    const end = start + limit;
    const transactions = partnerData.slice(start, end).map((data) => {
      const amount = ethers.utils.formatUnits(data?.amount, 16); // Since amount is * 10^16
      const total = amount * (data?.purchasePrice / 10 ** 18);
      return {
        date: new Date(data.purchaseDate.toNumber() * 1000).toLocaleString(),
        amount,
        total,
      };
    });

    return transactions;
  };

  return (
    signer && (
      <div className="shared-space ">
        <div className="CommissionsDetails">
          <h2 className="table-label">AiT Purchased Transactions</h2>
          <h2 className="table-label totalCommission"></h2>
          <div className="borderSpace"></div>
        </div>
        <CustomTable
          cb={transactionList}
          currentPage={currentPage}
          no={10}
          totalList={tableTotalList}
          Partner="true"
        />
        <Pagination
          totalList={tableTotalList}
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    )
  );
}

export default PurchasedPartner;
