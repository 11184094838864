import { toast } from "react-hot-toast";
import { debounce } from "lodash";
import { useRef, useEffect, useMemo } from "react";
import moment from "moment/moment";

/* This function Helper for setItem or Save in localStorage | khaled Mofeed | 5/12/2022 */
export function SaveLocalStorag(key, value) {
  return localStorage.setItem(key, value);
}
/* This function Helper for getItem from localStorage | khaled Mofeed | 5/12/2022 */
export function GetLocalStorag(key) {
  return localStorage.getItem(key);
}
/* This function Helper for Remove Item from localStorage | khaled Mofeed | 10/3/2023 */
export function RemoveLocalStorag(key) {
  localStorage.removeItem(key);
}

/*  Creating the countdown timer | khaled Mofeed | 20/12/2022 */
export function calculateTimeLeft(value) {
  let difference = value;
  let timeLeft = {};
  /*  write the logic for calculating time | khaled Mofeed | 29/10/2022 */
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)), // days
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24), // hours
      minutes: Math.floor((difference / 1000 / 60) % 60), // minutes
      seconds: Math.floor((difference / 1000) % 60), // seconds
    };
  }

  return timeLeft;
}

export const copyToClipboard = async (text) => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }
};

// make a funcion return url and pass query params and its values
// export const makeUrl = (params, url = window.location.href) => {
//   let query = Object.keys(params)
//     .map((k) => k + "=" + params[k])
//     .join("&");
//   return url + "?" + query;
// };
export const makeUrl = (params, url = window.location.href) => {
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;

  // Loop through the provided params object
  for (const key in params) {
    // If the key is 'sponsor', set the new value
    if (key === "sponsor") {
      searchParams.set(key, params[key]);
    } else {
      // If the key is not 'sponsor', append it to the searchParams
      searchParams.append(key, params[key]);
    }
  }

  // Update the search portion of the URL
  urlObject.search = searchParams.toString();

  return urlObject.toString();
};
export const phases = {
  DEFAULT: "default",
  BEFORE: "before",
  LIVE: "live",
  AFTER: "after",
};
Object.freeze(phases);

export const getPhase = (contract, start, end) => {
  let phase = phases.LIVE;
  if (!contract) return;
  const now = Math.floor(Date.now() / 1000);
  if (now < start) {
    phase = phases.BEFORE;
  }
  if (now > end) {
    phase = phases.AFTER;
  }
  return phase;
};

export const useDebounce = (cb, time) => {
  const callback = useRef(cb);
  useEffect(() => {
    callback.current = cb;
  }, [cb]);

  const debouncedCallback = useMemo(
    () => debounce((...args) => callback.current(...args), time),
    [time]
  );
  return debouncedCallback;
};

export const getPaginationAttributes = (responseData) => {
  return {
    options: responseData?.pagination_options?.last_page,
    count: responseData?.pagination_options?.count,
    total: responseData?.pagination_options?.total,
  };
};
/**
 * (timeRemainToday)
 * return the calculate of the remaining time
 * param args
 * author Khaled Mofeed
 */
var currentDate = new Date();
var hours = currentDate.getHours();
var minutes = currentDate.getMinutes();
var seconds = currentDate.getSeconds();
const timeNow = hours + ":" + minutes + ":" + seconds;
export const timeRemainToday = (start_time) => {
  const timeRemainToday = moment
    .utc(moment(start_time, "HH:mm:ss").diff(moment(timeNow, "HH:mm:ss")))
    .format("HH:mm");
  return timeRemainToday;
};
/**
 * (readFilesFromEvent)
 * return the reader of file
 * param args
 * author Khaled Mofeed
 */
export function readFilesFromEvent(event) {
  return new Promise((resolve, reject) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      reject(new Error("No files selected"));
    }

    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    reader.readAsDataURL(files[0]);
  });
}
/**
 * (fillFormData)
 * return the FormData fill
 * param args
 * author Khaled Mofeed
 */
export function fillFormData(form) {
  const formData = new FormData();
  for (const key in form) {
    formData.append(key, form[key]);
  }
  return formData;
}

export const formatAccountAddress = (account) => {
  return account.slice(0, 6) + "..." + account.slice(-4);
};

export const convertTotimestamp = (dateString) => {
  return Math.floor(Date.parse(dateString) / 1000);
};
