import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { getCommissionRate } from "../../presaleContractFunctions";
import arrOfShare from "../../../../configs/shareTelvAffiliate";
import { copyToClipboard, makeUrl } from "../../../../Helper/Helper";
import { BsQrCodeScan } from "react-icons/bs";

/*
 * react-share is a library that provides a set of React components for sharing links on social media.
 * @see https://www.npmjs.com/package/react-share
 */

import "./style.css";
import { IoCopy } from "react-icons/io5";
import QRCodeGenerator from "./QRCodeGenerator";
import { usePresale } from "../../../../scripts/usePresale";
import { useAccount } from "wagmi";

function Share() {
  const [commission, setCommission] = useState(0);
  const [smShow, setSmShow] = useState(false);
  const { userPresaleData } = usePresale();
  const { address } = useAccount();
  // a general function to get data from promise and store it in state
  const generalFunc = async (setter, cb) => {
    const data = await cb();
    setter(data);
  };
  // console.log("userPresaleData", userPresaleData.sponsor_);
  // const useEffectFunc = async () => {
  //   await Promise.all([generalFunc(setCommission, getCommissionRate)]);
  // };

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   useEffectFunc();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // those are the links that will be shared and the text that will be added to the link
  const shareUrl = makeUrl({ sponsor: address });
  const title = "Join the AiT private sale";

  // to copy the link to the clipboard
  const copyShareUrlToClipboard = () => {
    copyToClipboard(shareUrl);
  };

  return (
    <Row className='bg-live align-items-center justify-content-center shared-margin shared-space'>
      <Col xs={10} className='live-buy-section live-buy-sectionCard'>
        <Row className='first-row-in-share'>
          <Col md={4}>
            <h2>
              Share <span className='min-telv'>AiT</span>
            </h2>
            <p>private sale link</p>
          </Col>
          <Col md={8}>
            <h2>
              & Earn{" "}
              <span className='purple-span'>
                {/* {parseInt(commission) * 10} */}
                10 %
              </span>
            </h2>
            <p>of each affiliate’s purchase transact.</p>
          </Col>
        </Row>
        {/* <button onClick={togglePopup}>Show QR Code</button>
        {showPopup && <Popup onClose={togglePopup} url={shareUrl} />} */}

        <Modal
          size='sm'
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby='example-modal-sizes-title-sm'
          centered
          className='smShowModal'
        >
          <Modal.Body>
            <QRCodeGenerator url={shareUrl} />
          </Modal.Body>
        </Modal>
        {/* {console.log("shareUrl", shareUrl)}
        <p>{shareUrl} </p> */}
        <div className='second-row-in-share'>
          <div className='share-link'>
            {arrOfShare(shareUrl, title).map(
              ({ name, icon, onClick }, index) => {
                return (
                  <>
                    {" "}
                    <p onClick={onClick}>{shareUrl} </p>
                  </>
                );
              }
            )}
          </div>
          <div className='share-btns'>
            {arrOfShare(shareUrl, title).map(
              ({ name, icon, onClick }, index) => {
                return (
                  <>
                    <IoCopy className='cursor-pointer' onClick={onClick} />
                    <BsQrCodeScan
                      onClick={() => setSmShow(true)}
                      className='BsQrCodeScan'
                    />
                  </>
                );
              }
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Share;
