/*
 * ethers library is used to interact with the smart contract
 * @see https://docs.ethers.io/v5/
 * and the JSON file is used to store the ABI of the smart contract
 * @see https://docs.ethers.io/v5/api/utils/abi/
 */
import { ethers } from "ethers";
import abi from "../../../configs/abis/partner-contract.json";
import config from "../../../configs";
import { useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { AppContext } from "../../../scripts/context";

const contractAddress = config.get("smartContract.PARTNER_ADDRESS");
// let provider;
// if (window.ethereum) {
//   provider = new ethers.providers.Web3Provider(window.ethereum);
// }

// ^ Helper functions

// create a contract instance
export const makePartnerContract = (signer) => {
  // await window.ethereum.request({ method: "eth_requestAccounts" });
  const contract = new ethers.Contract(contractAddress, abi, signer);
  return contract;
};

export const PartnerTableData = async (func, signer) => {
  const deployedPresaleContract = await makePartnerContract(signer);
  const response = await deployedPresaleContract[func]();
  // const dataArr = rowData.transactions_ || rowData.commissions_;
  // console.log("dataArr", dataArr);
  // const transactions = await Promise.all(
  //   dataArr.map(async (item, index) => ({
  //     id: `${index}-${item.purchaseDate}`,
  //     amount: parseInt(item.amount) / 10 ** 16,
  //     date: new Date(item.purchaseDate * 1000).toLocaleDateString(),
  //     relation: parseInt(item.relation),
  //     address: item.userAddress,
  //     commission: parseInt(item.amount) / 10 ** 18,
  //   }))
  // );
  // const totalList = parseInt(totalList_);
  return response;
};

export const purchaseToken = async (balance, parchasprice, amount, signer) => {
  const deployedPresaleContract = await makePartnerContract(signer);
  // const amountT = amount * 10 ** 16;
  const valueAmount = "0x" + (amount * 10 ** 16).toString(16);
  const value = "0x" + (amount * parchasprice).toString(16);
  const valueDicimal = ethers.utils.formatUnits(value, 18);
  if (valueDicimal > balance) {
    throw new Error("Insufficient balance");
  }
  try {
    const transaction = await deployedPresaleContract.purchaseToken(
      valueAmount,
      {
        value,
      }
    );
    await transaction.wait(); // wait for the transaction to be mined
    toast.success("Tokens purchased successfully!");
    return transaction;
  } catch (error) {
    console.error("Transaction failed", error);
    toast.error("Transaction failed");
  }
};
