import {
  useReadContract,
  useWriteContract,
  useSimulateContract,
  useAccount,
  useBalance,
} from "wagmi";
import abi from "../../../configs/abis/presale-contract.json";
import config from "../../../configs";

const contractAddress = config.get("smartContract.CONTRACT_ADDRESS");

const contractConfig = {
  address: contractAddress,
  abi: abi,
};

export const useGetAddress = () => {
  const { address } = useAccount();
  return address;
};

export const useGetBalance = () => {
  const address = useGetAddress();
  const { data, isError, isLoading, error } = useBalance({
    address: address,
  });

  if (isError) {
    console.error("Error fetching balance:", error);
  }

  return { data, error, isLoading, isError };
};

export const useGetStartingDate = () => {
  const { data, error, isLoading, isError } = useReadContract({
    ...contractConfig,
    functionName: "getStartingDate",
  });

  if (error) {
    console.error("Error fetching starting date:", error);
  }

  const parseValue = parseInt(data);

  return { parseValue, isError, isLoading, isError };
};

export const useGetDeadlineDate = () => {
  const { data, error, isLoading, isError } = useReadContract({
    ...contractConfig,
    functionName: "getDeadlineDate",
  });

  if (error) {
    console.error("Error fetching deadline date:", error);
  }

  const parseValue = parseInt(data);

  return { parseValue, error, isLoading, isError };
};

export const useGetTokenDecimals = () => {
  const { data, error, isLoading, isError } = useReadContract({
    ...contractConfig,
    functionName: "getTokenDecimals",
  });

  if (error) {
    console.error("Error fetching token decimals:", error);
  }
  return { data, error, isLoading, isError };
};

export const useGetContractOwner = () => {
  const { data, error } = useReadContract({
    ...contractConfig,
    functionName: "getOwners",
  });

  if (error) {
    console.error("Error fetching contract owner:", error);
  }

  return { data, error };
};

const valueParser = (value, decimals) => {
  const parsedValue = parseInt(value);
  const parsedDecimals = parseInt(decimals);

  const result = parsedValue / 10 ** parsedDecimals;

  return result;
};
export const useSingleValueFunc = (func) => {
  const { data, error, isLoading, isError } = useReadContract({
    ...contractConfig,
    functionName: func,
  });

  if (error) {
    console.error(`Error fetching ${func}:`, error);
  }

  const parseValue = parseInt(data);

  return { parseValue, error, isLoading, isError };
};

export const useSingleValueFuncWithArgs = (func, args) => {
  const { data, error, isLoading, isError } = useReadContract({
    ...contractConfig,
    functionName: func,
    args: args,
  });

  if (error) {
    console.error(`Error fetching ${func}:`, error);
  }

  const parseValue = parseInt(data);

  return { parseValue, error, isLoading, isError };
};
export const useTableData = async (func, args) => {
  console.log("useTableData Func", func)
  console.log("useTableData args", args)
  const data = useReadContract({
    ...contractConfig,
    functionName: func,
    args,
  });

  // if (isError) {
  //   console.error(`Error fetching ${func}:`, error);
  // }
  

  // if (isLoading || isError || !data) return { transactions: [], totalList: 0 };

  // if (!Array.isArray(data) || data.length < 2) {
  //   console.error(`Unexpected data format:`, data);
  //   return { transactions: [], totalList: 0 };
  // }

  // const [rowData, totalList_] = data;

  // if (!rowData) {
  //   console.error(`rowData is undefined:`, rowData);
  //   return { transactions: [], totalList: 0 };
  // }

  // const dataArr = rowData.transactions_ || rowData.commissions_;

  // if (!Array.isArray(dataArr)) {
  //   console.error(`dataArr is not an array:`, dataArr);
  //   return { transactions: [], totalList: 0 };
  // }

  // const transactions = await Promise.all(
  //   dataArr.map(async (item, index) => ({
  //     id: `${index}-${item.purchaseDate}`,
  //     amount: parseInt(item.amount) / 10 ** 16,
  //     date: new Date(item.purchaseDate * 1000).toLocaleDateString(),
  //     relation: parseInt(item.relation),
  //     address: item.userAddress,
  //     commission: parseInt(item.amount) / 10 ** 18,
  //   }))
  // );

  // const totalList = parseInt(totalList_);
  // return { transactions, totalList };
};


export const useGetUserCommissionsList = (page, limit) => {
  return useTableData("getUserCommissionsList", [page, limit]);
};

export const useGetUserPurchasedTransactionsList = (page, limit) => {
  return useTableData("getUserPurchasedTransactionsList", [page, limit]);
};

export const useGetMinMaxAllocation = () => {
  const { data: decimalsData, error: decimalsError } = useGetTokenDecimals();
  const { data, isError, isLoading } = useReadContract({
    ...contractConfig,
    functionName: "getMinMaxAllocation",
  });

  if (isLoading || isError || decimalsError || !data || !decimalsData) {
    console.error(
      "Error fetching min/max allocation:",
      isError || decimalsError
    );
    return { minAllocation: 0, maxAllocation: 0 };
  }

  const decimals = decimalsData;
  const minAllocation = valueParser(data[0], decimals);
  const maxAllocation = valueParser(data[1], decimals);

  return { minAllocation, maxAllocation };
};

export const usePurchaseAiT = (telv, sponsor, i3) => {
  const {
    data: simulationData,
    isError: isSimulateError,
    isLoading: isSimulateLoading,
  } = useSimulateContract({
    ...contractConfig,
    functionName: "transferFrom",
    args: [telv, sponsor, i3],
  });

  const {
    write,
    isLoading: isWriteLoading,
    isError: isWriteError,
    error: writeError,
  } = useWriteContract({
    request: simulationData?.request,
  });

  if (isSimulateError) {
    console.error("Error simulating purchase:", isSimulateError);
  }

  if (isWriteError) {
    console.error("Error writing purchase:", writeError);
  }

  return {
    simulateResult: simulationData,
    isSimulateError,
    isSimulateLoading,
    write,
    isWriteLoading,
    isWriteError,
    writeError,
  };
};

export const useWhenAreWeNow = () => {
  const { data: startingDate } = useGetStartingDate();
  const { data: deadlineDate } = useGetDeadlineDate();

  if (!startingDate || !deadlineDate) return "loading";

  const now = Math.floor(Date.now() / 1000);
  if (now < startingDate) {
    return "before";
  }
  if (now > deadlineDate) {
    return "after";
  }
  return "live";
};
