import React, { useEffect, useState, useContext } from "react";
import { Container, Modal } from "react-bootstrap";
import MainHeader from "../Layout/Header";
import Footer from "../Layout/Footer";
import ComingSoon from "./ComingSoon";
import Live from "./Live";
import { AppContext } from "../../scripts/context";
import LoadPage from "./Live/components/LoadPage";
import LoadPagePartner from "./Live/components/LoadPagePartner";
import { usePresale } from "../../scripts/usePresale";
import LoadPageNotPartner from "./Live/components/LoadPageNotPartner";

function Index() {
  const { partnerData, purchaseTokens, isPartner } = usePresale();
  const {
    state: {
      contracts: {
        presale: {
          data: { time },
        },
      },
      user,
    } = {},
    reCallTime,
    setReCallTime,
  } = useContext(AppContext) || {};
  const statusObj = {
    // default: <DisconnectedWallet />,
    before: <ComingSoon />,
    live: <Live />,
    after: <Live />,
    load: <LoadPage />,
  };

  // this state is used to determine which component for render
  const [renderedComponent, setRenderedComponent] = useState(statusObj[time]);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (!time) return;
    setRenderedComponent(statusObj[time]);
  }, [time, user]);
  useEffect(() => {
    // Check if renderedComponent is undefined and set reCallTime to true
    if (renderedComponent === undefined) {
      setReCallTime(!reCallTime);
    }
  }, [renderedComponent]);
  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <Container fluid>
      <MainHeader />
      {/* partnerData?.length > 0 && isPartner  */}
      {partnerData?.length > 0 && isPartner ? (
        <LoadPagePartner
          partnerData={partnerData}
          purchaseTokens={purchaseTokens}
        />
      ) : (
        renderedComponent || <LoadPage />
      )}
      <Footer />

      {/* Bootstrap Modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="viewMain"
        show={showModal}
        onHide={handleCloseModal}
        centered
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to the AiTellit Members Area</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Congratulations on connecting your wallet! You're now just a few
            steps away from purchasing AiTellit tokens and becoming a part of
            our innovative community.
          </p>
          <p>Steps to Buy AiTellit Tokens:</p>
          <ul className="menuHome">
            <li>
              Verify Your Account: Ensure your account details are up-to-date.
            </li>
            <li>
              Select Token Quantity: Decide how many tokens you wish to
              purchase.
            </li>
            <li>
              Confirm Transaction: Review your order and confirm the
              transaction.
            </li>
            <li>
              Secure Your Tokens: Your tokens will be in your wallet
              immediately, make sure they are secured!
            </li>
          </ul>
          <p>
            Enjoy exclusive access to cutting-edge AI solutions and
            community-driven projects. Thank you for joining AiTellit!
          </p>
          <p className="mb-0">
            For support, contact us{" "}
            <a href="mailto:Info@aitellit.com" className="linkHere">
              here
            </a>
            .
          </p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </Container>
  );
}

export default Index;
