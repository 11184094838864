// eslint-disable-next-line import/no-anonymous-default-export
export default {
  smartContract: {
    TOKEN_ADDRESS:
      process.env.REACT_APP_TOKEN_ADDRESS ||
      "0x18502323aE2203BAAb8EcA21eBEf62583878ec7e",
    CONTRACT_ADDRESS:
      process.env.REACT_APP_CONTRACT_ADDRESS ||
      "0x269C7Bb2656c7BfBAd879373d3e12cc643F2DdC4",
    // CONTRACT_ADDRESS:
    //   process.env.REACT_APP_CONTRACT_ADDRESS ||
    //   "0x269C7Bb2656c7BfBAd879373d3e12cc643F2DdC4",
    API_URL:
      process.env.REACT_APP_API_URL || "https://api.aitellit.com/api/v1/",
    // 23/6/2024
    CHAIN_NAME: process.env.REACT_APP_CHAIN_NAME || "Binance Smart Chain",
    CHAIN_ID: process.env.REACT_APP_CHAIN_ID || 56,
    CHAIN_RPC_URL:
      process.env.REACT_APP_CHAIN_RPC_URL ||
      "https://bsc-dataseed.binance.org/",
    CHAIN_CURRENCY_SYMBOL: process.env.REACT_APP_CHAIN_CURRENCY_SYMBOL || "BNB",
    CHAIN_BLOCK_EXPLORER_URL:
      process.env.REACT_APP_CHAIN_BLOCK_EXPLORER_URL || "https://bscscan.com/",
    // CHAIN_NAME:
    //   process.env.REACT_APP_CHAIN_NAME || "Binance Smart Chain Testnet",
    // CHAIN_ID: process.env.REACT_APP_CHAIN_ID || 97,
    // CHAIN_RPC_URL:
    //   process.env.REACT_APP_CHAIN_RPC_URL ||
    //   "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // CHAIN_CURRENCY_SYMBOL:
    //   process.env.REACT_APP_CHAIN_CURRENCY_SYMBOL || "tBNB",
    // CHAIN_BLOCK_EXPLORER_URL:
    //   process.env.REACT_APP_CHAIN_BLOCK_EXPLORER_URL ||
    //   "https://testnet.bscscan.com/",

    VESTING_ADDRESS:
      process.env.REACT_APP_VESTING_ADDRESS ||
      "0x803AD40351ecC953d3AC9748681687db259D5490",
    NFT_CONTRACT_PROPOSAL_ADDRESS:
      process.env.REACT_APP_NFT_CONTRACT_PROPOSAL_ADDRESS ||
      "0xf5e68bbFb0fcc9C481E168F06a0774eb7d3aFd78",
    NFT_CONTRACT_ADDRESS:
      process.env.REACT_APP_NFT_CONTRACT_ADDRESS ||
      "0xB693832a0D66251bEcBC4A80B103e0ae52fFe856",
    PROPOSAL_ADDRESS:
      process.env.REACT_APP_PROPOSAL_ADDRESS ||
      "0xaA8c1068E12FB4607954123b6d88A49c931F965D",
    REVENUE_ADDRESS:
      process.env.REACT_APP_REVENUE_ADDRESS ||
      "0x1e8F4f44F57FE68D6cD8aC832DE08E2f4d0D1CbF",
    // "0x73F77e3883e093D7272874507A9dEbBb49e69A9D",
    MARKETPLACE_ADDRESS:
      process.env.REACT_APP_MARKETPLACE_ADDRESS ||
      "0x6562d3aC212336723b87620b27980a7a6C5B0bBD",
    NFT_MARKETPLACE_ADDRESS:
      process.env.REACT_APP_NFT_MARKETPLACE_ADDRESS ||
      "0xB611737ac773ca20B729179281010A58A70e508b",
    PARTNER_ADDRESS:
      process.env.REACT_APP_PARTNER_ADDRESS ||
      "0x53750F1aabF79C96f3822201285Ced74f00011b4",
  },
};
