import React, { useEffect } from "react";
import { Row } from "react-bootstrap";

function ComingSoon(props) {
  useEffect(() => {
    // Add class to body when component mounts
    document.body.classList.add("ISOVER");

    // Remove class from body when component unmounts
    return () => {
      document.body.classList.remove("ISOVER");
    };
  }, []); // Empty dependency array ensures this effect runs only once (on mount)

  return (
    <Row>
      <div className="start-soon-text">
        <h1>Private Token Sale will</h1>
        <h3>START SOON</h3>
      </div>
    </Row>
  );
}

export default ComingSoon;
