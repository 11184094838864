import React, { useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { IoCopy } from "react-icons/io5";
import { Tooltip } from "@mui/material";
import { ethers } from "ethers";
import PurchasedPartner from "./PurchasedPartner";
import { copyToClipboard } from "../../../../Helper/Helper";
// import this
import { useConnectorClient } from "wagmi";
import { watchAsset } from "viem/actions";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

function LoadPagePartner({ partnerData, purchaseTokens }) {
  // in the top of the component

  const { data: connectorClient } = useConnectorClient();

  const { mutateAsync } = useMutation({
    mutationFn: (asset) => watchAsset(connectorClient, asset),
  });

  const addTokenToWallet = async () => {
    const tokenAddress = "0xbBe63e7CD926b0D1C67f39d0703417D045cF7083"; // Replace with your token contract address
    const tokenSymbol = "AIT";
    const tokenDecimals = 16;
    const tokenImage =
      "https://play-lh.googleusercontent.com/72nZ2TZzD8t_rdifqw9QL4n6CR7Ow885nCfQkKC5YFmR1iAyCiLCQM2KR2RFDuDJRQ";

    try {
      if (connectorClient) {
        const wasAdded = await mutateAsync({
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        });

        if (wasAdded) {
          toast.success("Token added to your Wallet!");
        } else {
          toast.error("Token not added to your Wallet.");
        }
      } else {
        console.log("No provider found.");
      }
    } catch (error) {
      console.error("Error adding token to your wallet:", error);
    }
  };

  const [isPurchaseTokensLoading, setIsPurchaseTokensLoading] = useState(false);
  // const { refetch, isPartner, partnerData } = usePresale();

  const [amount, setAmount] = useState("");

  const handlePurchase = () => {
    setIsPurchaseTokensLoading(true);
    purchaseTokens(partnerData?.purchasePrice, amount)
      .then(() => setIsPurchaseTokensLoading(false))
      .catch((err) => setIsPurchaseTokensLoading(false));
  };
  function copyAdressToClipboard(address) {
    copyToClipboard(address);
  }
  // console.log("partnerDataxxxxxxx", partnerData);
  return (
    <Col className='live-presale-max-width mt-2'>
      <Row className='justify-content-center '>
        <Col xs={12} sm={10} lg={10} className='d-flex flex-column '>
          <div className='CommissionsDetails'>
            <h1 className='table-label orangeColor font-40'>Partner Gate </h1>
            <Tooltip
              title={
                "Add the token to your noncustodial wallet (MetaMask, Trust Wallet, etc.) and the token will appear in your wallet, This is a one-time process."
              }
              placement='bottom'
              arrow
              classes={{ popper: "custom-tooltip" }} // Apply the custom class
            >
              <span
                className='presale-submit-btn AddTokenBtn'
                onClick={addTokenToWallet}
              >
                Add Token to your Wallet
              </span>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row className='justify-content-center '>
        <Col xs={12} sm={10} lg={4} className='d-flex flex-column '>
          <div className='CommissionsDetails'>
            <h2 className='table-label orangeColor'>Buy AiT Tokens </h2>
            <h2 className='table-label totalCommission'></h2>
            <div className='borderSpace'></div>
          </div>
          <Row className='bg-live align-items-center justify-content-center shared-margin shared-space  mxh-85'>
            <Col xs={10} className='live-buy-section '>
              <h4 className=''>White-listed Address</h4>
              <div className='bnb-address d-flex justify-content-between align-items-center'>
                <div className='addressPartner'>
                  <span className='mr-2'>
                    {/* {partnerData[0]} */}

                    {partnerData[0] &&
                      partnerData[0].substring(0, 8) +
                        "..." +
                        partnerData[0].substring(partnerData[0].length - 8)}
                  </span>
                  <IoCopy
                    className='cursor-pointer'
                    onClick={() => copyAdressToClipboard(partnerData[0])}
                  />
                </div>
              </div>
              <div class='borderSpaceTop'></div>
              <div className='bnb-address d-flex justify-content-between align-items-center'>
                Balance
                <p>
                  {parseFloat(
                    ethers.utils.formatUnits(partnerData?.balance, 16)
                  ).toFixed(3)}{" "}
                  AiT
                  {/* {(partnerData?.balance / 10) *
                    16 *
                    ((partnerData?.purchasePrice / 10) * 18)}{" "} */}
                </p>
              </div>
              <div className='bnb-address d-flex justify-content-between align-items-center'>
                Price
                <p className='text-right'>
                  0.017 USD<br></br>
                  {ethers.utils.formatUnits(partnerData?.purchasePrice, 18)} BNB
                  {/* {(partnerData?.balance / 10) *
                    16 *
                    ((partnerData?.purchasePrice / 10) * 18)}{" "} */}
                </p>
              </div>
              <div class='borderSpaceBottom'></div>
              <div className='presal-amount'>
                <h6 className='d-flex align-self-start'>
                  Amount To Purchase {"\u00A0"}
                  {/* <span className="minAllo-span">
                    {" "}
                    
                  </span> */}
                </h6>
                <div className='d-flex justify-content-between align-items-center pb-2'>
                  {/* <input
                    type="number"
                    // max={Number((balance / exchangeRate) * 100).toFixed(2) || 0}
                    // placeholder={"Min is: " + minimum}
                    placeholder="Enter Amount"
                    lang="en"
                    pattern="[0-9]*[.,]?[0-9]+"
                    className="setNFTHandlerInput"
                  /> */}
                  <input
                    type='number'
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder='Enter Amount'
                    lang='en'
                    pattern='[0-9]*[.,]?[0-9]+'
                    className='setNFTHandlerInput'
                  />

                  {/* <Tooltip title={"Purchase Price"} placement="top" arrow>
                    <span>
                      {ethers.utils.formatUnits(partnerData?.purchasePrice, 18)}{" "}
                      BNB
                    </span>
                  </Tooltip> */}
                </div>
                <hr />
              </div>

              <div class='mt-3'>
                {isPurchaseTokensLoading ? (
                  <span
                    className='presale-submit-btn'
                    disabled={isPurchaseTokensLoading}
                  >
                    Submit
                    <Spinner
                      style={{ marginLeft: ".5rem" }}
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      id='ID_Spinner'
                    />
                  </span>
                ) : (
                  <span className='presale-submit-btn' onClick={handlePurchase}>
                    Submit
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={10} lg={6}>
          <Row>
            <Col className='live-presale-now mb-5'>
              <PurchasedPartner
                partnerData={partnerData?.transactions}
                purchasePrice={ethers.utils.formatUnits(
                  partnerData?.purchasePrice,
                  18
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default LoadPagePartner;
