import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./style.css";
import { IconButton, Tooltip } from "@mui/material";

function CustomTable({
  cb,
  currentPage,
  no,
  affiliateList,
  totalCommission,
  setTotalCommission,
  totalList,
  Partner,
}) {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]); // New state to store all data
  const fetchData = async () => {
    const result = await cb(no);
    setData(result);
  };
  // Fetch all data
  const fetchAllData = async () => {
    // Assume cb() can be used to fetch all data. Modify if necessary.
    const allResults = await cb(totalList);
    setAllData(allResults);
    calculateTotalCommission(allResults); // Calculate total commission from all data
  };

  const calculateTotalCommission = (data) => {
    const total = data.reduce((acc, item) => acc + (item.commission || 0), 0);
    setTotalCommission?.(total);
  };

  useEffect(() => {
    fetchData();
  }, [cb, currentPage, no]);

  useEffect(() => {
    fetchAllData();
  }, [totalList]);

  return (
    <div className="bg-live table-container ">
      <Table className="purchasing-table">
        <thead>
          <tr>
            <th> Date</th>
            {!affiliateList && <th> Amount </th>}
            {affiliateList && (
              <>
                <th> Address </th>
                <th> Relation</th>
                <th> Commission </th>
              </>
            )}
            {Partner && <th>total</th>}
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <tr>
              <td className="table-no-data" colSpan={affiliateList ? 6 : 3}>
                No Data Found
              </td>
            </tr>
          ) : (
            data.map(
              ({
                id,
                amount,
                date,
                address,
                relation,
                commission,
                total,
                status = "Complete",
              }) => {
                return (
                  <tr key={id}>
                    <td>{date}</td>
                    {affiliateList && (
                      <>
                        <th>
                          <Tooltip title={address} placement="top" arrow>
                            <IconButton className="totalPriceH2">
                              {address.slice(0, 4) + "..." + address.slice(-4)}{" "}
                            </IconButton>
                          </Tooltip>
                        </th>
                        <th> {relation}</th>
                        <th>
                          <Tooltip title={commission} placement="top" arrow>
                            <IconButton className="totalPriceH2 pr-3">
                              {commission.toFixed(6)}
                            </IconButton>
                          </Tooltip>
                          BNB{" "}
                        </th>
                      </>
                    )}
                    {!affiliateList && <td>{amount}</td>}
                    {Partner && <th>{total}</th>}
                    <td>
                      <span
                        className={
                          status === "Complete" || status === "Ready"
                            ? "purchase-state complete-purchase"
                            : status === "Claimed"
                            ? "purchase-state claimed-purchase"
                            : "purchase-state incomplete-purchase"
                        }
                      >
                        {status}
                      </span>
                    </td>
                  </tr>
                );
              }
            )
          )}
        </tbody>
      </Table>
      {/* {affiliateList && (
        <div className="total-commission">
          Total Commission: {totalCommission.toFixed(6)} BNB
        </div>
      )} */}
    </div>
  );
}

export default CustomTable;
